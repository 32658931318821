import React from "react"
import Layout from '../components/template/Layout'
import NewsList from '../components/lists/NewsList'
import { Container } from '@material-ui/core'

export default function Actualites() {
  return (
    <Layout title="Nos Actualités" description="Actualités de la Jeune Chambre Economique de Châteaubriant et sa région" type="Liste Actualités" image="https://chateaubriant.jcef.asso.fr/static/b6563a053b3907c2c82b4c56278a3648/2a4de/logo.png">
      <Container>
        <NewsList limit="100"/>
      </Container>
    </Layout>
  );
}

